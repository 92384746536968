import { createStore, applyMiddleware, compose } from 'redux';
import combinedReducer from './reducers';
import thunk from 'redux-thunk';
import { initialState } from './reducers/index';

// Store for storing state of the whole app
const store = createStore(
  combinedReducer,
  initialState,
  compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f
  )
);

export default store;
