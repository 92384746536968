import React from 'react';
import { Link } from 'react-router-dom';

const Logo = ({height, width, src}) => {
  return (
    <div className='align-center'>
      <Link to='/'>
      <img src={src} width={width} height={height} />
      </Link>
    </div>
  );
};

export default Logo;
