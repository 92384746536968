import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Button from '@material-ui/core/Button';
import Logo from '../components/Logo';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/dt-logo.png';
import { ducky } from '../store/actions';
import { connect } from 'react-redux';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import './styles.scss';

const FindDucky = props => {
  const history = useHistory();
  const handleSubmit = () => {
    const dt_code = document.getElementById('dt-code').value;
    axios
      .get('/find', {
        params: {
          id: dt_code
        }
      })
      .then(function (response) {
        props.ducky((response.data))
        history.push('/track');
      })
      .catch(function (error) {});
  }

  return (
    <div className='centered'>
      <Logo width='350' src={logo} />
      <div className='align-center'>
        <Typography variant='subtitle1' className='font-bold'>
          Welcome! To Ducky Tracker.
        </Typography>
        <Typography variant='caption'>
          Enter your DT Code # in the text box below. <br />
          The code can be found on the tag and on the bottom sticker.
        </Typography>
      </div>
      <div className='mt-5'>
        <FormControl variant='outlined'>
          <InputLabel htmlFor='dt-code'>DT Code #</InputLabel>
          <OutlinedInput
            id='dt-code'
            type='text'
            className='MuiOutlinedInput-adornedEnd'
            endAdornment={
              <InputAdornment position='end'>
                <span className='vl' />
                <IconButton edge='end'>
                  <FontAwesomeIcon icon={faQrcode} />
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>
      </div>
      <div className='mt-5'>
        <Button
          variant='outlined'
          className='mr-5 width-100'
          onClick={handleSubmit}
        >
          SUBMIT
        </Button>
        <Button
          component={Link}
          to={'/register'}
          variant='outlined'
          className='ml-5 width-100'
        >
          REGISTER
        </Button>
        <Button
          component={Link}
          to={'/travellers'}
          variant='outlined'
          className='ml-10 width-105'
        >
          Travel Logs
        </Button>
      </div>
    </div>
  );
};

export default connect(
  (state, _) => ({ ducky: state.ducky }),
  dispatch => ({
    ducky: ducky_detail => dispatch(ducky(ducky_detail)),
  })
)(FindDucky);
