// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React from 'react'
import ReactDOM from 'react-dom'
import FindDucky from '../pages/FindDucky';
import DuckyDetail from '../pages/Detail';
import RegisterDucky from '../pages/RegisterDucky';
import store from '../store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import SharePage from '../pages/SharePage';
import Travellers from '../pages/Travellers';

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path='/' component={FindDucky} />
          <Route exact path='/register' component={RegisterDucky} />
          <Route exact path='/track' component={DuckyDetail} />
          <Route exact path='/share' component={SharePage} />
          <Route exact path='/travellers' component={Travellers} />
        </Switch>
      </Router>
    </Provider>,
    document.body.appendChild(document.createElement('div'))
  );
})
