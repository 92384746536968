import React, { useEffect, useState } from "react";
import "./styles.scss";
import Button from "@material-ui/core/Button";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";

const SharePage = () => {
  const history = useHistory();
  const location = useLocation();
  const [imageUrl, setImageUrl] = useState(location.state?.imageUrl || "");

  const handleShare = (socialMedia) => {
    switch (socialMedia) {
      case "Facebook":
        shareOnFacebook();
        break;
      case "Twitter":
        shareOnTwitter();
        break;
      default:
        console.log("Social media not supported");
    }
  };

  const handleDownload = () => {
    if (!imageUrl) return;

    const a = document.createElement("a");
    a.href = imageUrl;
    a.download = "DuckyTracker.jpg";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const shareOnTwitter = () => {
    if (!imageUrl) return;
    
    const text = encodeURIComponent("I just registered my Ducky! Follow their journey on ");
    const hashtags = encodeURIComponent("DuckyTracker");
    const encodedImageUrl = encodeURIComponent(imageUrl);
    const pageUrl = encodeURIComponent("www.DuckyTracker.com");
    
    const shareUrl = `https://twitter.com/intent/tweet?text=${text}&url=${pageUrl}&hashtags=${hashtags}`;
  
    window.open(shareUrl, "_blank");
  };

  const shareOnFacebook = () => {
    if (!imageUrl) return;
    const text = encodeURIComponent("I just registered my Ducky! Follow their journey on ");
    const pageUrl = encodeURIComponent("www.DuckyTracker.com");
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(pageUrl)}`;
    window.open(shareUrl, '_blank');
  };

  
  useEffect(() => {
    window.FB.init({
      appId      : '3637863769817319',
      status     : true,
      xfbml      : true,
      version    : 'v2.7'
    });
  }, []);
  

  return (
    <div className="share-page">
      <div className="share-container">
        <img
          src={imageUrl}
          alt="Map"
          width={50}
          height={50}
          className="share-image"
        />
        <p>
          I just registered my Ducky! Follow their journey on{" "}
          <a
            href="https://www.DuckyTracker.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.DuckyTracker.com
          </a>
        </p>
        <div className="share-buttons-container">
          <Button
            variant="outlined"
            className="mr-5 width-120"
            onClick={() => handleDownload()}
          >
            Download
          </Button>
          <Button
            variant="outlined"
            className="mr-5 width-120"
            onClick={() => handleShare("Facebook")}
          >
            Share to Facebook
          </Button>
          <Button
            variant="outlined"
            className="mr-5 width-120"
            onClick={() => handleShare("Twitter")}
          >
            Share to Twitter
          </Button>
          <Button
            variant="outlined"
            className="mr-5 width-120"
            onClick={() => history.goBack()}
          >
            Go Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SharePage;
