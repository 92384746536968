import { combineReducers } from 'redux';
import duckyReducer, { initialState as ducky } from './duckyReducer';

// A combined reducer consisting of all of the app's reducers
const combinedReducer = combineReducers({
  ducky: duckyReducer
});

export const initialState = {
  ducky: ducky
};

export default combinedReducer;
