import { DUCKY_TRACKER } from '../actions';

export const initialState = {
  ducky: null
};

// The state reducer for Authentication
const DuckyReducer = (state = initialState, action) => {
  switch (action.type) {
    case DUCKY_TRACKER:
      return {
        ducky: action.payload
      };
    default:
      return state;
  }
};

export default DuckyReducer;
