import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import Geocode from 'react-geocode';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import logo from '../../assets/images/dt-logo-reg.png';
import Logo from '../components/Logo';
import axios from 'axios';
import './styles.scss';
import Collapse from '@mui/material/Collapse';

const RegisterDucky = props => {
  const history = useHistory();
  const query = new URLSearchParams(props.location.search);
  const [duckyTrackerCode, setDuckyTrackerCode] = React.useState(
    query.get('dt-code')
  );
  const [userCity, setUserCity] = useState(null);
  const [checked, setChecked] = useState(false);
  const [userState, setUserState] = useState(null);
  const [userLongitude, setUserLongitude] = useState(null);
  const [userLatitude, setUserLatitude] = useState(null);

  const notify = message => toast(message);
  const handleInternational = () => {
    setChecked(prev => !prev);
  };
  const handleSubmit = () => {
    const email = document.getElementById('email').value;
    const locationCheckBox =
      document.getElementById('LocationCheckBox').checked;
    const internationalCheckBox = document.getElementById(
      'InternationalCheckBox'
    ).checked;
    const zipcode = document.getElementById('zipcode').value;
    const dt_code = document.getElementById('dt-code').value;
    const name = document.getElementById('name').value;
    const vehicle = document.getElementById('vehicle').value;

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!pattern.test(email)) {
      notify('Please enter valid email address.');
      return;
    }

    const body = new FormData();
    body.set('tracker_info[dt_code]', dt_code);
    body.set('tracker_info[name]', name);
    body.set('tracker_info[email]', email);
    body.set('tracker_info[zip_code]', zipcode);
    body.set('tracker_info[vehicle]', vehicle);

    if (locationCheckBox == false) {
      axios
        .get(
          'https://maps.googleapis.com/maps/api/geocode/json?address=' +
            zipcode +
            '&sensor=true&key=AIzaSyAimYThsDwc9_fBhXL0mN5lpSA7VuhdOxQ'
        )
        .then(response => {
          debugger
          if (response.status == 200) {
            let city, state, country;
            for (
              let i = 0;
              i < response.data.results[0].address_components.length;
              i++
            ) {
              for (
                let j = 0;
                j < response.data.results[0].address_components[i].types.length;
                j++
              ) {
                switch (
                  response.data.results[0].address_components[i].types[j]
                ) {
                  case 'locality':
                    city =
                      response.data.results[0].address_components[i].long_name;
                    break;
                  case 'administrative_area_level_1':
                    state =
                      response.data.results[0].address_components[i].long_name;
                    break;
                  case 'country':
                    country =
                      response.data.results[0].address_components[i].long_name;
                    break;
                }
              }
            }
            if (internationalCheckBox == true) {
              body.set(
                'tracker_info[city]',
                document.getElementById('city').value
              );
              body.set(
                'tracker_info[state]',
                document.getElementById('state').value
              );
            } else {
              body.set('tracker_info[city]', city);
              body.set('tracker_info[state]', state);
            }
            body.set(
              'tracker_info[longitude]',
              response.data.results[0].geometry.location.lng
            );
            body.set(
              'tracker_info[latitude]',
              response.data.results[0].geometry.location.lat
            );
            if (city != 'undefined' && state != 'undefined') saveFormData(body);
            else notify('Invalid Location.');
          } else {
            notify('Invalid Zip Code.');
          }
        })
        .catch(function (error) {
          notify(error.message);
        });
    } else if (zipcode != '') {
      handleGEOLocation();
      body.set('tracker_info[latitude]', userLatitude);
      body.set('tracker_info[longitude]', userLongitude);
      if (internationalCheckBox == true) {
        body.set('tracker_info[city]', document.getElementById('city').value);
        body.set('tracker_info[state]', document.getElementById('state').value);
      } else {
        body.set('tracker_info[city]', userCity);
        body.set('tracker_info[state]', userState);
      }
      if (
        userState != 'undefined' &&
        userCity != 'undefined' &&
        userLatitude != null &&
        userLongitude != null
      )
        saveFormData(body);
      else notify('Invalid Location.');
    } else {
      notify('All fields are required!');
    }
  };

  const saveFormData = body => {
    fetch('/tracker', {
      method: 'POST',
      body
    })
      .then(response => {
        if (response.status == 200) {
          notify('Ducky Registered Successfully!');
          setTimeout(function () {
            history.push('/');
          }, 2000);
        } else {
          notify('All fields are required!');
          setTimeout(function () {
            history.push('/register');
          }, 2000);
        }
      })
      .catch(error => notify(error));
  };

  React.useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setUserLatitude(position.coords.latitude);
      setUserLongitude(position.coords.longitude);
    });
  }, [userLongitude, userLatitude]);

  const handleLocationCheckbox = value => {
    if (value.target.checked) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setUserLatitude(position.coords.latitude);
        setUserLongitude(position.coords.longitude);
      });
      handleGEOLocation();
    }
  };

  const handleGEOLocation = () => {
    Geocode.setApiKey('AIzaSyAimYThsDwc9_fBhXL0mN5lpSA7VuhdOxQ');
    Geocode.fromLatLng(userLatitude, userLongitude).then(
      response => {
        const address = response.results[0].formatted_address;
        let city, state, country;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case 'locality':
                city = response.results[0].address_components[i].long_name;
                break;
              case 'administrative_area_level_1':
                state = response.results[0].address_components[i].long_name;
                break;
              case 'country':
                country = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        setUserCity(city);
        setUserState(state);
      },
      error => {
        console.error(error);
      }
    );
  };

  const onDTCodeChange = e => {
    setDuckyTrackerCode(e);
  };

  return (
    <div className='centered'>
      <ToastContainer />
      <Logo width='320' src={logo} />
      <div className='align-left'>
        <Typography variant='subtitle1' className='font-bold'>
          Register your Ducky.
        </Typography>
        <Typography variant='caption'>
          Your information will not be sold to Third Parties or
          <br /> be used for Spam of any kind.
        </Typography>
      </div>
      <div className='mt-5'>
        <FormControl variant='outlined' className='form-width'>
          <InputLabel htmlFor='dt-code'>DT Code #</InputLabel>
          <OutlinedInput
            id='dt-code'
            value={duckyTrackerCode}
            placeholder='2002,2003,2004...'
            onChange={e => onDTCodeChange(e.target.value)}
            name='tracker_info[dt_code]'
            className='MuiOutlinedInput-adornedEnd'
            endAdornment={
              <InputAdornment position='end'>
                <span className='vl' />
                <IconButton>
                  <FontAwesomeIcon icon={faQrcode} />
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />

          <TextField
            id='name'
            name='tracker_info[name]'
            variant='outlined'
            label='Name'
            className='MuiOutlinedInput-root mt-15'
          />

          <TextField
            id='email'
            name='tracker_info[email]'
            variant='outlined'
            label='Email'
            className='MuiOutlinedInput-root mt-15'
          />

          <TextField
            id='vehicle'
            name='tracker_info[vehicle]'
            variant='outlined'
            label='Vehicle'
            className='MuiOutlinedInput-root mt-15'
          />

          <div style={{ textAlign: 'initial' }}>
            <TextField
              style={{ display: 'flex' }}
              id='zipcode'
              name='tracker_info[zipcode]'
              variant='outlined'
              label='Zip Code'
              className='MuiOutlinedInput-root mt-15'
            />

            <FormControlLabel
              control={
                <Checkbox
                  name='LocationCheckBox'
                  id='LocationCheckBox'
                  color='primary'
                  onClick={e => handleLocationCheckbox(e)}
                />
              }
              label='Enable current loaction.'
            />
          </div>
          <FormControlLabel
            control={
              <Checkbox
                name='InternationalCheckBox'
                id='InternationalCheckBox'
                color='primary'
                onClick={e => handleInternational()}
              />
            }
            label='International'
          />
          <Collapse in={checked}>
            <div style={{ textAlign: 'initial' }}>
              <TextField
                style={{ display: 'flex' }}
                id='city'
                name='tracker_info[city]'
                variant='outlined'
                label='City'
                className='MuiOutlinedInput-root mt-15'
              />

              <TextField
                style={{ display: 'flex' }}
                id='state'
                name='tracker_info[State]'
                variant='outlined'
                label='State'
                className='MuiOutlinedInput-root mt-15'
              />
            </div>
          </Collapse>

          <FormControlLabel
            className='mt-15 mr-5'
            control={<Checkbox name='checkedB' color='primary' />}
            label='Receive Email Notification.'
          />

          <FormControlLabel
            className='mr-5'
            control={<Checkbox name='checkedB' color='primary' />}
            label={
              <div>
                <span>I Read and </span>
                <a target='_blank' href='https://www.duckytracker.com/terms'>
                  Accept Terms & Condition.
                </a>
              </div>
            }
          />
        </FormControl>
      </div>
      <div className='mt-5'>
        <Button
          variant='outlined'
          className='mr-5 width-100'
          onClick={handleSubmit}
        >
          SUBMIT
        </Button>
      </div>
    </div>
  );
};

export default RegisterDucky;
