import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/dt-logo-reg.png';
import travellersBackground from '../../assets/images/travellers-page-bg.jpg';
import Logo from '../components/Logo';
import './styles.scss';
import { Box, Typography } from '@material-ui/core';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Travellers = props => {
  const [recentRecords, setRecentRecords] = useState([]);
  const [mostTravelledRecords, setMostTravelledRecords] = useState([]);
  useEffect(() => {
    axios
      .get('/travellers_data')
      .then(function (response) {
        console.log(response.data);
        setRecentRecords(response.data[0]);
        setMostTravelledRecords(response.data[1]);
      })
      .catch(function (error) {});
  }, []);

  const formatDate = (date) => {
    const originalDate = new Date(date);

    const month = originalDate.getMonth() + 1;
    const day = originalDate.getDate();
    const year = originalDate.getFullYear();

    return`${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;

  }
  return (
    <>
      <Logo width='200' src={logo} />
      <div className='bg-color-white'>
        <div
          className='image-container'
          style={{ height: '70vh', position: 'relative' }}
        >
          <img
            src={travellersBackground}
            alt='Travellers Background'
            className='full-width'
            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
          />
        </div>

        <div className='centered-table'>
          <div className='gray-background-card'>
            <Typography variant='h5' component='h5'>
              <Box sx={{ fontWeight: 'bold', m: 1 }}>Recent Travelers</Box>
            </Typography>
            <table className='rounded-corners'>
              <thead>
                <tr>
                  <th>
                    <Typography variant='body2'>
                      <Box sx={{ fontWeight: 550 }}>Ducky #</Box>
                    </Typography>
                  </th>
                  <th>
                    <Typography variant='body2'>
                      <Box sx={{ fontWeight: 550 }}>Date Registered</Box>
                    </Typography>
                  </th>
                  <th>
                    <Typography variant='body2'>
                      <Box sx={{ fontWeight: 550 }}>First Location</Box>
                    </Typography>
                  </th>
                  <th>
                    <Typography variant='body2'>
                      <Box sx={{ fontWeight: 550 }}>Last Location</Box>
                    </Typography>
                  </th>
                  <th>
                    <Typography variant='body2'>
                      <Box sx={{ fontWeight: 550 }}>Locations</Box>
                    </Typography>
                  </th>
                </tr>
              </thead>
              <tbody>
                {recentRecords.map(record => (
                  <tr
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    <td>
                      <Typography variant='body2'>
                        <Link
                          to={`/track?dt-code=${record.dt_code}`}
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          <Box
                            sx={{ textDecoration: 'underline' }}
                          >{`DT#${record.dt_code}`}</Box>
                        </Link>
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='body2'>
                        {formatDate(record.first_registered_at)}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='body2'>
                        {record.first_registered_city}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='body2'>
                        {record.last_registered_city}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='body2'>
                        {record.frequency}
                      </Typography>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className='gray-background-card'>
            <Typography variant='h5' component='h5'>
              <Box sx={{ fontWeight: 'bold', m: 1 }}>Most Traveled</Box>
            </Typography>
            <table className='rounded-corners'>
              <thead>
                <tr>
                  <th>
                    <Typography variant='body2'>
                      <Box sx={{ fontWeight: 550 }}>Top 10</Box>
                    </Typography>
                  </th>
                  <th>
                    <Typography variant='body2'>
                      <Box sx={{ fontWeight: 550 }}>Ducky #</Box>
                    </Typography>
                  </th>
                  <th>
                    <Typography variant='body2'>
                      <Box sx={{ fontWeight: 550 }}>Locations</Box>
                    </Typography>
                  </th>
                  <th>
                    <Typography variant='body2'>
                      <Box sx={{ fontWeight: 550 }}>First Location</Box>
                    </Typography>
                  </th>
                  <th>
                    <Typography variant='body2'>
                      <Box sx={{ fontWeight: 550 }}>Last Location</Box>
                    </Typography>
                  </th>
                </tr>
              </thead>
              <tbody>
                {mostTravelledRecords.map((record, index) => (
                  <tr
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '5px'
                        }}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          height='15'
                          width='12'
                          viewBox='0 0 576 512'
                          style={{ marginRight: '4px', marginTop: '1px' }} // Add margin-right here
                        >
                          <path
                            opacity='1'
                            fill='#aed756'
                            d='M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z'
                          />
                        </svg>
                        <Typography variant='body2'>{index + 1}</Typography>
                      </div>
                    </td>
                    <td>
                      <Typography variant='body2'>
                        <Link
                          to={`/track?dt-code=${record.dt_code}`}
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          <Box
                            sx={{ textDecoration: 'underline' }}
                          >{`DT#${record.dt_code}`}</Box>
                        </Link>
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='body2'>
                        {record.frequency}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='body2'>
                        {record.first_registered_city}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant='body2'>
                        {record.last_registered_city}
                      </Typography>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Travellers;
