import React from 'react';
import logo from '../../assets/images/dt-logo-reg.png';
import pin from '../../assets/images/dt-pin-icon.png';
import Logo from '../components/Logo';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import GoogleMap from '../components/GoogleMap';
import html2canvas from 'html2canvas';
import {ducky} from '../store/actions/ducky'
import axios from 'axios';
import './styles.scss';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const Detail = props => {
  const query = new URLSearchParams(props.location.search);
  const [duckyTrackerCode, setDuckyTrackerCode] = React.useState(
    query.get('dt-code')
  );
  const history = useHistory();

  React.useEffect(() => {
    if(duckyTrackerCode){
      axios
        .get('/find', {
          params: {
            id: duckyTrackerCode
          }
        })
        .then(function (response) {
          props.getDucky(response.data);
        })
        .catch(function (error) {});
    }
  },[duckyTrackerCode]);

  const capture = () => {
    const captureElement = document.getElementById('map-capture-area') || document.body;

    html2canvas(captureElement, { useCORS: true, allowTaint: false }).then(
      function (canvas) {
        const elementWidth = captureElement.offsetWidth;
        const elementHeight = captureElement.offsetHeight;

        const cropXPercentage = 50;
        const cropYPercentage = 0;
        const cropWidthPercentage = 100;
        const cropHeightPercentage = 100;

        const cropX = (cropXPercentage / 100) * elementWidth;
        const cropY = (cropYPercentage / 100) * elementHeight;
        const cropWidth = (cropWidthPercentage / 100) * elementWidth;
        const cropHeight = (cropHeightPercentage / 100) * elementHeight;

        const croppedCanvas = document.createElement('canvas');
        croppedCanvas.width = cropWidth;
        croppedCanvas.height = cropHeight;

        const croppedCtx = croppedCanvas.getContext('2d');

        croppedCtx.drawImage(
          canvas,
          cropX, cropY, cropWidth, cropHeight,
          0, 0, cropWidth, cropHeight
        );

        const imageUrl = croppedCanvas.toDataURL('image/jpeg');

        history.push({
          pathname: '/share',
          state: { imageUrl },
        });
      }
    );
  };
  return props.ducky.ducky == null || props.ducky.ducky.length == 0 ? (
    <div>
      <Logo width='200' src={logo} />
      No Record found
    </div>
  ) : (
    <>
      <Logo width='200' src={logo} />
      <GoogleMap mapData={props.ducky.ducky} />
      <div className='bg-color-white'>
        <div className='centered-map'>
          <Typography variant='subtitle1' className='font-bold pl-10'>
            DT#{props.ducky.ducky[0].dt_code}
          </Typography>
          <Typography variant='caption' className='pl-10'>
            Follow your Ducky's Jeep Journey. Here are some of the places it has
            visited:
          </Typography>
          <br />
          <ul className='list'>
            {props.ducky.ducky.map(d => (
              <div>
                <li className='mt-15'>
                  <div className='float-left mt-25'>
                    <Logo width='20' src={pin} />
                  </div>
                  <div className='inline-grid ml-10'>
                    <Typography variant='caption' className='short-name'>
                      {d.name}
                    </Typography>
                    <Typography variant='subtitle1' className='font-bold-black'>
                      {d.city}, {d.state}
                    </Typography>
                    <Typography variant='caption' className='short-name'>
                      {d.vehicle}
                    </Typography>
                  </div>
                </li>
              </div>
            ))}
            <div pl-10>
              <Button
                variant='outlined'
                className='mr-5 width-120'
                onClick={() => capture()}
              >
                SHARE MAP
              </Button>
              <Button variant='outlined' className='mr-5 width-120'>
                <a
                  href='https://duckytracker.com/shop/'
                  className='decoration-none'
                  target='_blank'
                >
                  SHOP
                </a>
              </Button>
              <Button variant='outlined' className='mr-5 width-120'>
                <a href='/' className='decoration-none' target='_self'>
                  TRACK
                </a>
              </Button>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  ducky: state.ducky
});

const mapDispatchToProps = dispatch => ({
  getDucky: (ducky_detail) => {
    dispatch(ducky(ducky_detail))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
